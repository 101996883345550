import { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PublicRoute from "./routes/publicRoute/PublicRoute";
import PrivateRoute from "./routes/privateRoute/PrivateRoute";
import Loading from "./components/loading/Loading.js";
import Landing from "./Pages/Landing/Landing";

import routes from "./routes/routes";

import { authActions } from "./redux-state/actions/auth.actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import AppWrapper from "./components/appWrapper/AppWrapper";

const Login = lazy(() => import("./Pages/login/Login.js"));
const Registracija = lazy(() => import("./Pages/Registracija/Registracija"));

function App() {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.authentication);
  useEffect(() => {
    console.log("check authentication");
    dispatch(authActions.user());
  }, []);

  return !isAuthenticated.checking ? (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute
              Component={<Landing />}
              isAuthenticated={isAuthenticated.loggedIn}
            />
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute
              Component={<Login />}
              isAuthenticated={isAuthenticated.loggedIn}
            />
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute
              Component={<Registracija />}
              isAuthenticated={isAuthenticated.loggedIn}
            />
          }
        />

        {routes.map(({ component: Component, path, subroutes }) => (
          <Route
            path={`${path}`}
            key={path}
            element={
              <PrivateRoute
                Component={
                  <AppWrapper>
                    <Component />
                  </AppWrapper>
                }
                isAuthenticated={isAuthenticated.loggedIn}
              />
            }
          >
            {subroutes
              ? subroutes.map(({ path, subcomponent: SubComponent }) => {
                  console.log("subroute::", SubComponent);
                  return (
                    <Route
                      path={`${path}`}
                      key={path}
                      element={
                        <PrivateRoute
                          Component={
                            <AppWrapper>
                              <SubComponent />
                            </AppWrapper>
                          }
                          isAuthenticated={isAuthenticated.loggedIn}
                        />
                      }
                    ></Route>
                  );
                })
              : ""}
          </Route>
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  ) : (
    <Loading />
  );
}

export default App;
