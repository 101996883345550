import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import user from "../../assets/images/user.png";
import "./TopBar.scss";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { imagePath } from "../../helpers/imagePath";

const TopBar = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.authentication.user);

  console.log(userInfo);
  // useEffect(()=>{
  //   dispatch()
  // },[])

  return (
    <div className="top-bar">
      <div className="img-wrapper logo">
        <Link to="/">
          <img src={logo} alt="EkoSpace" />
        </Link>
      </div>
      <Link to="/profile">
        <div className="profile">
          <div className="img-wrapper user">
            <img src={imagePath + userInfo.user_image_path} alt="User" />
          </div>
          <p className="imePrezime">{userInfo.name}</p>
        </div>
      </Link>
    </div>
  );
};

export default TopBar;
