import { authConstants } from "../constants";

const initialState = {
  checking: true,
  loggedIn: false,
  loggingIn: false,
  error: false,
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggedIn: false,
        loggingIn: true,
        error: false,
        user: action.user,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        error: false,
        user: action.user,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        loggingIn: false,
        error: true,
      };
    case authConstants.LOGOUT:
      return {
        loggedIn: false,
        loggingIn: false,
        error: false,
      };
    default:
      return state;
  }
}
