import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ Component, location, isAuthenticated, ...rest }) => {
  return !isAuthenticated ? (
    Component
  ) : (
    <Navigate
      replace
      to={{
        pathname: "/home",
        // state: { from: location },
      }}
    />
  );
};

export default PublicRoute;
