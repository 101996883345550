export const postsConstants = {
  POSTS_GET: "POSTS_GET",
  POSTS_MAKE: "POSTS_MAKE",
  POSTS_DELETE: "POSTS_DELETE",
  POSTS_UPDATE: "POSTS_UPDATE",
  POSTS_LIKE: "POSTS_LIKE",
  POSTS_COMMENT: "POSTS_COMMENT",
  POSTS_REQUEST: "POSTS_REQUEST",
  POSTS_FAIL: "POSTS_FAIL",
};
