import { userConstants } from "../constants";
// import { userService } from "../services/user.service";

import axiosClient from "../../apiClient/apiClient";

export const userActions = {
    getInfo,
    post,
    getInfoById
};

function getInfo() {
    return async (dispatch) => {
        await getInfoService()
            .then((res) => {
                console.log(res);
                dispatch(success(res));
            })
            .catch((err) => {
                console.log(err);
                dispatch(failure(err));
            });
    };
}
function getInfoById(user_id) {
    return async (dispatch) => {
        await getInfoServiceById(user_id)
            .then((res) => {
                console.log(res);
                dispatch(success(res));
            })
            .catch((err) => {
                console.log(err);
                dispatch(failure(err));
            });
    };
}

async function getInfoService() {
    return axiosClient.get("/api/profile").then((res) => {
        console.log("resresres", res);
        return res.data.user;
    });
}

async function getInfoServiceById(user_id) {
    return axiosClient.get("/api/profile/" + user_id).then((res) => {
        console.log("resresres", res);
        return res.data.user;
    });
}

function post(post_image_path, post_description, post_city) {
    return async (dispatch) => {
        const formData = new FormData();
        formData.append("post_image_path", post_image_path);
        formData.append("post_description", post_description);
        formData.append("post_city", post_city);
        axiosClient
            .post("/api/posts", formData)
            .then(async (res) => {
                await getInfoService().then((res) => {
                    console.log("successs", res);
                    dispatch(success(res));
                });
            })
            .catch((err) => {
                dispatch(failure(err));
            });
    };
}
function request(user) {
    return { type: userConstants.USER_INFO, user };
}
function success(user) {
    return { type: userConstants.USER_INFO, user };
}
function failure(error) {
    return { type: userConstants.FAIL, error };
}
