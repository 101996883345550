import React from "react";
import "./app-wrapper.css";
import Nav from "../Nav/Nav";
import TopBar from "../TopBar/TopBar";

const AppWrapper = ({ children }) => {
  return (
    <div className="app-layout d-flex">
      <TopBar />
      <div className="app-content">{children}</div>
      <Nav></Nav>
    </div>
  );
};

export default AppWrapper;
