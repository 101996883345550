import React, { useState, useContext } from "react";

const ModalContext = React.createContext();

export const useModal = () => {
  return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
  const [vidljivModalObjavi, setVidljivModalObjavi] = useState(false);

  return (
    <ModalContext.Provider
      value={{ vidljivModalObjavi, setVidljivModalObjavi }}
    >
      {children}
    </ModalContext.Provider>
  );
};
