import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

import "./Landing.scss";

const Landing = () => {
  return (
    <div className="landing-page">
      <div className="landing-img-wrapper logo">
        <img src={logo} alt="EkoSpace" />
      </div>

      <div className="button-wrapper">
        <Link to="/login" className="button-link">
          <button id="login-button" type="button" className="">
            Login
          </button>
        </Link>
        <Link to="/register" className="button-link">
          <button id="register-button" type="button" className="">
            Registracija
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Landing;
