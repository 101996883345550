import React from "react";
import "./FilterGroup.scss";

const FilterGroup = (props) => {
  return (
    <div className="filter-group">
      <ul>
        {props.buttons.map((dugme, index) => (
          <li key={index}>{dugme}</li>
        ))}
      </ul>
    </div>
  );
};

export default FilterGroup;
