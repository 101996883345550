import { lazy } from "react";
import Profile from "../Pages/Profile/Profile";

const routes = [
  {
    path: "home",
    component: lazy(() => import("../Pages/Home/Home")),
    exact: true,
  },
  {
    path: "search",
    component: lazy(() => import("../Pages/Search/Search")),
    exact: true,
  },
  {
    path: "notifications",
    component: lazy(() => import("../Pages/Notifications/Notifications")),
    exact: true,
  },
  {
    path: "leaderboard",
    component: lazy(() => import("../Pages/LeaderBoard/LeaderBoard")),
    exact: true,
  },
  {
    path: "profile",
    component: Profile,
    exact: true,
  },
  {
    path: "profile/:user_id",
    component: Profile,
    exact: true,
  },
];

export default routes;
