import axiosClient from "../../apiClient/apiClient";

export const citiesActions = {
  getCities,
};

function getCities() {
  return async (dispatch) => {
    axiosClient
      .get("/api/cities")
      .then((res) => {
        let cities = res.data.cities;
        dispatch({ type: "GET_CITIES", cities });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
