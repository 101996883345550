import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ Component, isAuthenticated }) => {
  return isAuthenticated ? (
    Component
  ) : (
    <Navigate
      replace
      to={{
        pathname: "/login",
        // state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
