import React, { useEffect, useState } from "react";
// import user from "../../assets/images/user.png";
import FilterGroup from "../../components/FilterGroup/FilterGroup";
import "./Profile.scss";
import PostList from "./../../components/PostList/PostList";
import { FaCaretDown } from "react-icons/fa";
import { useModal } from "../../data/modalContext";
import { BsFillImageFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux-state/actions/user.actions";
import { citiesActions } from "../../redux-state/actions/cities.actions";
import Loading from "../../components/loading/Loading";
import { imagePath } from "../../helpers/imagePath";
import { useLocation } from "react-router-dom";

const ModalObjavi = (props) => {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.cities.cities);
  const [errorPoruka, setErrorPoruka] = useState("");
  // const options = ["Beograd", "Cacak", "Novi Sad"];
  const [aktivanDropDown, setAktivanDropDown] = useState(false);
  const [opisObjave, setOpisObjave] = useState("");
  const [odabranaLokacija, setOdabranaLokacija] = useState("Beograd");
  const [image, setImage] = useState("");

  function handleChange(e) {
    setOpisObjave(e.target.value);
  }
  function handleImageChange(e) {
    setImage(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!opisObjave || !image || /^\s*$/.test(opisObjave)) {
      setErrorPoruka("Sva polja moraju biti popunjena");
    } else {
      console.log(e.target.imageInput.files[0]);
      dispatch(
        userActions.post(
          e.target.imageInput.files[0],
          opisObjave,
          odabranaLokacija
        )
      );
      props.closeModal();
    }
  }
  useEffect(() => {
    if (errorPoruka.length > 0) {
      setTimeout(() => {
        setErrorPoruka("");
      }, 3000);
    }
  }, [errorPoruka]);

  useEffect(() => {
    dispatch(citiesActions.getCities());
    document.getElementById("body").style.overflow = "hidden";
    // document.getElementById("body").style.paddingRight = "17px";
    return () => {
      document.getElementById("body").style.overflow = "auto";
      // document.getElementById("body").style.paddingRight = "unset";
    };
  }, []);

  return (
    <form className="modal-objavi" onSubmit={(e) => handleSubmit(e)}>
      <div className="lokacija">
        <p>Lokacija:</p>
        <div
          className="dropdown"
          onClick={() => {
            setAktivanDropDown(!aktivanDropDown);
          }}
        >
          <div className="dropdown-btn">
            {odabranaLokacija}
            <FaCaretDown size={26} color="#205372" />
          </div>
          {(errorPoruka !== "" && odabranaLokacija === "" ? "error" : "") && (
            <span className="error">{errorPoruka}</span>
          )}
          {aktivanDropDown && (
            <div className="dropdown-content">
              {options.map((item, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={(e) => {
                    setOdabranaLokacija(e.target.textContent);
                  }}
                >
                  {item.city_name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="deskripcija">
        <p>Deskripcija: </p>
        <textarea
          value={opisObjave}
          placeholder="Opisi ekoloski problem"
          onChange={handleChange}
          className="deskripcija-tekst"
          rows="4"
          cols="50"
        ></textarea>
        {errorPoruka !== "" && opisObjave === "" ? (
          <span className="error">{errorPoruka}</span>
        ) : (
          ""
        )}
      </div>
      <div className="slika dugmad">
        <button type="button" className="image-button dugme dodaj">
          <BsFillImageFill></BsFillImageFill>
          <label htmlFor="imageInput">Dodaj sliku</label>
        </button>
        <input
          id="imageInput"
          value={image}
          onChange={handleImageChange}
          name="image_path"
          type="file"
          hidden
        />
      </div>
      <div className="dugmad">
        <button
          className="dugme ponisti"
          onClick={() => props.closeModal(false)}
        >
          Ponisti
        </button>
        <button type="submit" className="dugme objavi">
          Objavi
        </button>
      </div>
    </form>
  );
};

const Profile = () => {
  const location = useLocation();

  const { vidljivModalObjavi, setVidljivModalObjavi } = useModal();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const loaded = useSelector((state) => state.user.loaded);

  useEffect(() => {
    if (location.pathname.split("/")[2] == null)
      dispatch(userActions.getInfo());
    else dispatch(userActions.getInfoById(location.pathname.split("/")[2]));
  }, []);
  useEffect(() => {
    console.log(user);
  }, [user]);

  function closeModal() {
    setVidljivModalObjavi(false);
  }

  const buttons = [
    <button className="filter-dugme">Objave</button>,
    <button className="filter-dugme">Prosli dogadjaji</button>,
    <button className="filter-dugme">Aktivni dogadjaji</button>,
  ];

  return loaded && user ? (
    <div className="profile-page">
      <div className="user-info">
        <div className="img-wrapper profile">
          <img src={`${imagePath}${user.user_image_path}`} alt="User" />
        </div>
        <div className="user-tekst">
          <p className="imePrezime">{user.name}</p>
          <p>
            Points:{" "}
            <span className="user-points">{user.points_sum_point_value}</span>
          </p>
          <p className="deskripcija">{user.user_description}</p>
        </div>
      </div>
      <FilterGroup buttons={buttons} />
      <PostList isProfile={true} posts={user.posts} />
      {vidljivModalObjavi && (
        <ModalObjavi closeModal={closeModal}></ModalObjavi>
      )}
    </div>
  ) : (
    <Loading />
  );
};

export default Profile;
