import React, { useState } from "react";
import "./ModalInfluenser.scss";
import axiosClient from "./../../apiClient/apiClient";
import { postsActions } from "../../redux-state/actions/posts.actions";
import { useDispatch } from "react-redux";

const ModalInfluenser = (props) => {
  const [infoDogadjaja, setInfoDogadjaja] = useState({
    post_verified_description: "",
    post_action_at: "",
  });

  const dispatch = useDispatch();
  const handleChange = (e) => {
    setInfoDogadjaja((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePodrzi = () => {
    dispatch(postsActions.verify(props.post.id, infoDogadjaja));
    props.closeModal(false);
  };
  return (
    <div className="modal-influenser">
      <p>Informacije o dogadjaju: </p>
      <textarea
        value={infoDogadjaja.post_verified_description}
        name="post_verified_description"
        placeholder="Daj tacne informacije o dogadjaju(adresa, sta ce se raditi)"
        onChange={handleChange}
        className="influenser-info-dogadjaj"
        rows="4"
        cols="50"
      ></textarea>
      <input
        type="date"
        name="post_action_at"
        value={infoDogadjaja.post_action_at}
        autoComplete="off"
        onChange={handleChange}
      />
      <div className="dugmad">
        <button
          className="dugme ponisti"
          onClick={() => props.closeModal(false)}
        >
          Odustani
        </button>
        <button type="submit" className="dugme objavi" onClick={handlePodrzi}>
          Podrži
        </button>
      </div>
    </div>
  );
};

export default ModalInfluenser;
