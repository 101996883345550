import React from "react";
import { AiFillHome, AiOutlineSearch, AiFillBell } from "react-icons/ai";
import { MdLeaderboard } from "react-icons/md";
import "./Nav.scss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { useModal } from "./../../data/modalContext";

const Nav = () => {
  const { setVidljivModalObjavi } = useModal();
  const location = useLocation();
  let aktivnaStrana = 1;
  switch (location.pathname) {
    case "/":
      aktivnaStrana = 1;
      break;
    case "/search":
      aktivnaStrana = 2;
      break;
    case "/notifications":
      aktivnaStrana = 3;
      break;
    case "/leaderboard":
      aktivnaStrana = 4;
      break;
    case "/profile":
      aktivnaStrana = 5;
      break;
    default:
      aktivnaStrana = 1;
      break;
  }

  return (
    <nav className="main-nav">
      <ul>
        <Link to="/" className={aktivnaStrana === 1 ? "active" : ""}>
          <AiFillHome color={aktivnaStrana === 1 ? "#fff" : "#205372"} />
        </Link>

        <Link to="/search" className={aktivnaStrana === 2 ? "active" : ""}>
          <AiOutlineSearch color={aktivnaStrana === 2 ? "#fff" : "#205372"} />
        </Link>

        {aktivnaStrana === 5 && (
          <AiFillPlusCircle
            color="#349e9e"
            size={45}
            onClick={() => setVidljivModalObjavi(true)}
            style={{ cursor: "pointer" }}
          />
        )}

        <Link
          to="/notifications"
          className={aktivnaStrana === 3 ? "active" : ""}
        >
          <AiFillBell color={aktivnaStrana === 3 ? "#fff" : "#205372"} />
        </Link>

        <Link to="/leaderboard" className={aktivnaStrana === 4 ? "active" : ""}>
          <MdLeaderboard color={aktivnaStrana === 4 ? "#fff" : "#205372"} />
        </Link>
      </ul>
    </nav>
  );
};

export default Nav;
