import React, { useEffect } from "react";
import Post from "../Post/Post";
import "./PostList.scss";
import { useDispatch } from "react-redux";
import { postsActions } from "../../redux-state/actions/posts.actions";
import { useSelector } from "react-redux";

import Loading from "../loading/Loading";

const PostList = (props) => {
  const dispatch = useDispatch();
  const postsTmp = useSelector((state) => state.posts.posts);
  const loaded = useSelector((state) => state.posts.loaded);

  const posts = props.posts ? props.posts : postsTmp;
  useEffect(() => {
    dispatch(postsActions.get());
  }, []);

  return (
    <div className="post-list">
      {loaded && posts !== null ? (
        <ul>
          {posts.map((post, index) => {
            return (
              <li key={index}>
                <Post post={post} />
              </li>
            );
          })}
        </ul>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default PostList;
