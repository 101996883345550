export const userConstants = {
  USER_INFO: "GET_USER_INFO",
  USER_POSTS: "GET_USER_POSTS",
  MAKE_POST: "MAKE_USER_POST",
  COMMENT_POST: "GET_USER_INFO",
  REQUEST: "USER_REQUEST",
  FAIL: "USER_FAIL",
  //   LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  //   LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  //   LOGOUT: "USERS_LOGOUT",
};
