import { authConstants } from "../constants";
// import { userService } from "../services/user.service";

import axiosClient, { setToken } from "../../apiClient/apiClient";

export const authActions = {
  user,
  login,
  logout,
  register,
};

function login(username, password) {
  return async (dispatch) => {
    dispatch(request({ username }));

    axiosClient
      .post("/api/login", { email: username, password: password })
      .then((res) => {
        console.log(res);
        localStorage.setItem("token", res.data.token);
        setToken();
        axiosClient
          .get("/api/me")
          .then((res) => {
            return dispatch(success(res.data.user));
          })
          .catch((err) => {
            return dispatch(failure(err));
          });
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(failure(err));
      });

    // await userService.login(username, password).then(
    //   (user) => {
    //     console.log(user);
    //     // dispatch(success(user));
    //     // history.push("/");
    //   },
    //   (error) => {
    //     console.log(error);
    //     // dispatch(failure(error));
    //     // dispatch(alertActions.error(error));
    //   }
    // );
  };
}

async function getMe() {
  return axiosClient.get("/api/me").then((res) => {
    console.log(res);
    return res.data.user;
  });
}

function user() {
  return async (dispatch) => {
    // dispatch(success({ name: "abc" }));

    return axiosClient
      .get("/api/me")
      .then((res) => {
        console.log(res);
        return dispatch(success(res.data.user));
      })
      .catch((err) => {
        console.log("err", err);
        return dispatch(failure(err));
      });
  };
}

function register(data) {
  return async (dispatch) => {
    axiosClient
      .post("/api/register", data)
      .then((res) => {
        console.log(res);
        localStorage.setItem("token", res.data.token);
        setToken();
        axiosClient
          .get("/api/me")
          .then((res) => {
            return dispatch(success(res.data.user));
          })
          .catch((err) => {
            return dispatch(failure(err));
          });
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(failure(err));
      });
  };
}

function request(user) {
  return { type: authConstants.LOGIN_REQUEST, user };
}
function success(user) {
  return { type: authConstants.LOGIN_SUCCESS, user };
}
function failure(error) {
  return { type: authConstants.LOGIN_FAILURE, error };
}

function logout() {
  return async (dispatch) => {
    localStorage.removeItem("token");
    axiosClient
      .post("/api/logout")
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {});
    return { type: authConstants.LOGOUT };
  };
}
