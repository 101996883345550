import { combineReducers } from "redux";
import sampleReducer from "./sampleReducer";
import { alert } from "./alert.reducer";
import { authentication } from "./authentication.reducer";
import { postReducer } from "./post.reducer";
import { posts } from "./posts.reducer";
import { userReducer } from "./user.reducer";
import { citiesReducer } from "./cities.reducer";

const reducers = combineReducers({
  account: sampleReducer,
  alertReducer: alert,
  authentication: authentication,
  postReducer: postReducer,
  posts: posts,
  user: userReducer,
  cities: citiesReducer,
});

export default reducers;
