import { postsConstants } from "../constants";

const initialState = {
  loaded: false,
  error: false,
  posts: null,
};

export function posts(state = initialState, action) {
  switch (action.type) {
    case postsConstants.POSTS_MAKE:
      return { ...state, loaded: true, error: false, posts: action.posts };
    case postsConstants.POSTS_GET:
      return { ...state, loaded: true, error: false, posts: action.posts };
    case postsConstants.POSTS_LIKE:
      return { ...state, loaded: true, posts: action.posts };
    case postsConstants.POSTS_COMMENT:
      return { ...state, loaded: true, posts: action.posts };
    case postsConstants.POSTS_FAIL:
      return { ...state, loaded: true, error: action.error };
    case postsConstants.POSTS_REQUEST:
      return { ...state, loaded: false };
    default:
      return state;
  }
}
