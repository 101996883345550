import React, { useState, useRef, useEffect } from "react";
import "./Post.scss";
import logo from "../../assets/images/logo.png";
import user from "../../assets/images/user.png";
import { IoHandRightSharp, IoLocationSharp, IoSend } from "react-icons/io5";
import { FaCommentAlt, FaHeart } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { postsActions } from "../../redux-state/actions/posts.actions";
import { useSelector } from "react-redux";
import { imagePath } from "../../helpers/imagePath";

import Komentar from "./../Komentar/Komentar";
import ModalInfluenser from "../ModalInfluenser/ModalInfluenser";
import { Link } from "react-router-dom";

const Post = ({ post }) => {
  const roleId = useSelector((state) => state.authentication.user.role_id);
  const isInfluencer = roleId == 1 ? true : false;
  const dispatch = useDispatch();
  const commentInput = useRef();
  const commentScroll = useRef();
  const [vidljiviKomentari, setVidljiviKomentari] = useState({
    show: false,
    text: "",
  });
  const [modalInfluenser, setModalInfluenser] = useState(false);
  const [dogadjajInfo, setDogadjajInfo] = useState(false);

  function handleLike() {
    if (post.is_liked) {
      dispatch(postsActions.unlike(post.id));
    } else {
      dispatch(postsActions.like(post.id));
    }
  }
  function handleParticipate() {
    if (isInfluencer) {
      setModalInfluenser(true);
      return;
    }
    if (post.is_participate) {
      dispatch(postsActions.unparticipate(post.id));
    } else {
      dispatch(postsActions.participate(post.id));
    }
  }

  function handleCommentChange(e) {
    e.preventDefault();

    setVidljiviKomentari({ ...vidljiviKomentari, text: e.target.value });
  }
  function handleCommentSubmit(e) {
    e.preventDefault();
    if (!vidljiviKomentari || /^\s*$/.test(vidljiviKomentari)) {
      commentInput.current.style.border = "green 1px solid";
      setTimeout(() => {
        commentInput.current.style.border = "";
      }, 2000);
    } else {
      dispatch(postsActions.comment(post.id, vidljiviKomentari.text));
      setVidljiviKomentari({ show: false, text: "" });
      commentScroll.current.scrollTop = commentScroll.current.scrollHeight;
    }
  }

  return (
    <div className="post">
      <div className="top-gradient"></div>
      <div className="bottom-gradient"></div>
      <img src={`${imagePath}${post.post_image_path}`} alt="Dogadjaj 1" />
      <div className="content">
        <div className="top-info">
          <p className="lokacija">
            <IoLocationSharp size={15} />
            <span style={{ paddingLeft: "5px" }}>{post.city.city_name}</span>
          </p>
          {post.verified_by !== null && (
            <>
              <div
                className="influenser"
                onMouseOver={() => setDogadjajInfo(true)}
                onMouseLeave={() => setDogadjajInfo(false)}
              >
                <div className="img-wrapper user">
                  <img
                    src={
                      post.verified_by
                        ? imagePath + post.verified_by.user_image_path
                        : ``
                    }
                    alt="User"
                  />
                </div>
                <p className="imePrezime">{post.verified_by.name}</p>
              </div>
              {dogadjajInfo && (
                <div className="dogadjaj-info">
                  <p>
                    <span style={{ fontWeight: 500 }}>Datum:</span>{" "}
                    {post.post_action_at &&
                      post.post_action_at.toString().slice(0, 10)}
                  </p>
                  <p>
                    <span style={{ fontWeight: 500 }}>Opis:</span>{" "}
                    {post.post_verified_description}
                  </p>
                </div>
              )}
            </>
          )}
          <p className="broj-poena">9XP</p>
        </div>
        <div className="bottom-info">
          <Link
            to={`/profile/${post.user_id}`}
            params={{ user_id: post.user_id }}
          >
            <div className="user">
              <div className="img-wrapper user">
                <img
                  src={
                    post.creator ? imagePath + post.creator.user_image_path : ``
                  }
                  alt="User"
                />
              </div>
              <p className="imeUsera">{post.creator.name}</p>
            </div>
          </Link>
          <p className="opis-posta">{post.post_description}</p>
          <div className="funkcionalnosti">
            <div className="lajk" onClick={() => handleLike()}>
              <FaHeart color={post.is_liked ? "#FB5454" : "#fff"} />
              <span>{post.likes_count}</span>
            </div>
            <div
              className="komentar"
              onClick={() =>
                setVidljiviKomentari({ ...vidljiviKomentari, show: true })
              }
            >
              <FaCommentAlt color="#fff" />
              <span>{post.comments.length}</span>
            </div>
            {(post.participants_count != 0 || isInfluencer) && (
              <div className="ucestvujem" onClick={() => handleParticipate()}>
                <IoHandRightSharp
                  color={post.is_participate ? "#FFDC5F" : "#fff"}
                />
                <span>{post.participants_count}</span>
              </div>
            )}
          </div>
        </div>
        {vidljiviKomentari.show && (
          <div className="komentari">
            <div
              className="kom-line"
              onClick={() => setVidljiviKomentari({ text: "", show: false })}
            ></div>
            <ul ref={commentScroll}>
              {post.comments.map((comment, i) => (
                <li key={i}>
                  <Komentar comment={comment} />
                </li>
              ))}
            </ul>
            <form
              action=""
              className="komentarisi-forma"
              onSubmit={handleCommentSubmit}
            >
              <input
                ref={commentInput}
                type="text"
                className="komentarisi"
                value={vidljiviKomentari.text}
                onChange={handleCommentChange}
              />
              <button
                className="submit"
                onClick={(e) => handleCommentSubmit(e)}
              >
                <IoSend color="#349e9e" />
              </button>
            </form>
          </div>
        )}
        {modalInfluenser && (
          <ModalInfluenser closeModal={setModalInfluenser} post={post} />
        )}
      </div>
    </div>
  );
};

export default Post;
