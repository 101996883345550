import { postConstants } from "../constants";

const initialState = {
  loaded: false,
  error: false,
  posts: null,
};

export function postReducer(state = initialState, action) {
  switch (action.type) {
    case postConstants.POST_LIKE:
      return {
        loaded: true,
        posts: true,
      };
    case postConstants.POST_COMMENT:
      return { ...state, loaded: true, posts: action.posts };
    case postConstants.POST_FAIL:
      return { ...state, loaded: true, error: action.error };

    default:
      return state;
  }
}
