import axios from "axios";
import { authHeader } from "./auth-header";

const axiosClient = axios.create();

// authHeader();

axiosClient.defaults.baseURL = "https://hakaton2022.darkbulb.rs/";

// /sanctum/csrf-cookie
axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: authHeader(),
};

// axiosClient.defaults.withCredentials = true;

//All request will wait 2 seconds before timeout
// axiosClient.defaults.timeout = 5000;

export default axiosClient;

export function setToken() {
  axiosClient.defaults.headers = {
    Authorization: authHeader(),
  };
}
