export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("token");

  if (token) {
    console.log("vrati token", token);
    return "Bearer " + token;
  } else {
    return null;
  }
}
