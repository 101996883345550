// import { alertConstants } from "../constants/alert.constants";

export function citiesReducer(state = [], action) {
  switch (action.type) {
    case "GET_CITIES":
      return {
        cities: action.cities,
      };
    default:
      return state;
  }
}
