import React from "react";
// import user from "../../assets/images/user.png";
import "./Komentar.scss";
import { imagePath } from "../../helpers/imagePath";

const Komentar = ({ comment }) => {
  return (
    <div className="komentar-glavni">
      <div className="komentator">
        <div className="img-wrapper user">
          <img src={`${imagePath}${comment.user.user_image_path}`} alt="User" />
        </div>
        <p className="imePrezime">{comment.user.name}</p>
      </div>
      <p className="komentar-tekst">{comment.comment_text}</p>
    </div>
  );
};

export default Komentar;
