import { postsConstants } from "../constants";

import axiosClient from "../../apiClient/apiClient";

export const postsActions = {
  get,
  post,
  comment,
  like,
  unlike,
  participate,
  unparticipate,
  verify,
  search
};

function get() {
  return async (dispatch) => {
    axiosClient
      .get("api/posts")
      .then((res) => {
        dispatch(success(res.data.posts));
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}

function search(text) {
  return async (dispatch) => {
    axiosClient
      .get("api/posts?search=" + text)
      .then((res) => {
        dispatch(success(res.data.posts));
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}

function like(id) {
  return async (dispatch) => {
    axiosClient
      .put(`api/posts/${id}/like`)
      .then((res) => {
        dispatch(success(res.data.posts));
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}
function unlike(id) {
  return async (dispatch) => {
    axiosClient
      .put(`api/posts/${id}/unlike`)
      .then((res) => {
        dispatch(success(res.data.posts));
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}
function participate(id) {
  return async (dispatch) => {
    axiosClient
      .put(`api/posts/${id}/participate`)
      .then((res) => {
        dispatch(success(res.data.posts));
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}
function verify(id, info) {
  return async (dispatch) => {
    axiosClient
      .post(`/api/posts/${id}/verify`, {
        ...info,
        _method: "PUT",
      })
      .then((res) => {
        dispatch(participate(id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
function unparticipate(id) {
  return async (dispatch) => {
    axiosClient
      .put(`api/posts/${id}/unparticipate`)
      .then((res) => {
        dispatch(success(res.data.posts));
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}

function post(
  post_image_path,
  post_description,
  post_city,
  post_points,
  post_type_id
) {
  return async (dispatch) => {
    axiosClient
      .post("/api/posts", {
        post_image_path,
        post_description,
        post_city,
        post_points,
        post_type_id,
      })
      .then((res) => {
        dispatch(success(res.data.posts));
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
function comment(id, comment_text) {
  return async (dispatch) => {
    axiosClient
      .post(`/api/posts/${id}/comments`, {
        comment_text,
      })
      .then((res) => {
        dispatch(success(res.data.posts));
      })
      .catch((err) => {
        console.log(err);
        dispatch(failure(err));
      });
  };
}

function request() {
  return { type: postsConstants.POSTS_REQUEST };
}
function success(posts) {
  return { type: postsConstants.POSTS_GET, posts };
}
function failure(error) {
  return { type: postsConstants.POSTS_FAIL, error };
}
