import { userConstants } from "../constants";

const initialState = {
  user: null,
  loaded: false,
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_INFO:
      return {
        ...state,
        loaded: true,
        user: action.user,
      };
    // case userConstants.USER_POSTS:
    //   return {
    //     ...state,
    //     loaded: true,

    //     posts: payload.posts,
    //   };
    case userConstants.MAKE_POST:
      return {
        ...state,
        loaded: true,
        user: action.user,
      };
    case userConstants.COMMENT_POST:
      return {
        ...state,
        loaded: true,

        posts: action.posts,
      };
    case userConstants.REQUEST:
      return {
        ...state,
        loaded: false,
      };
    case userConstants.FAIL:
      return {
        ...state,
        loaded: true,
        error: action.err,
      };

    // case userConstants.USER_COMMENT:
    //   return { ...state, loaded: true, user: action.user };
    // case userConstants.USER_FAIL:
    //   return { ...state, loaded: true, error: action.error };

    default:
      return state;
  }
}
